import axios from 'axios';

function getDate(timeZone = 'local'){
    const date = new Date;
    const dateOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',

    }
    const timeOptions ={
        timeZone: timeZone === 'local' ? Intl.DateTimeFormat().resolvedOptions().timeZone : timeZone,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hourCycle: 'h24'
    }
    let formattedDate = new Intl.DateTimeFormat('en-ca', dateOptions).format(date)
    let formattedTime = new Intl.DateTimeFormat('en-ca', timeOptions).format(date)
    return `${formattedDate}T${formattedTime}`
}


let subdomain = window.location.hostname.split('.')[0]
console.log(subdomain)
let appTypeUrl = null

switch (subdomain) {
    case 'test-auto':
    case 'test-cash':
    case 'test-nordwind':
    case 'test-vtb':
    case 'test-travelata':
    case 'test-bankiros':
    case 'test-southwind':
        appTypeUrl = 'https://test-card.paylate.ru/'
        break;
    case 'auto':
    case 'cash':
    case 'nordwind':
    case 'vtb':
    case 'bankiros':
    case 'travelata':
    case 'southwind':
        appTypeUrl = 'https://card.paylate.ru/'
        break;
    default:
        appTypeUrl = 'https://test-card.paylate.ru/'
        break;
}


function proxyConnectorToken(url, data) {
    let config = {
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        url: appTypeUrl + url,
        data : data
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {resolve(response.data);})
            .catch(err => reject(err));
    });
}

function dadata(url, query, parts) {
    var dadataUrl = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/";
    var token = "8ea87af5d3bb2c80b6f051d1c6c153f26d65eef4";

    var options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + token
        },
        body: JSON.stringify({query, parts})
    }

    return fetch(dadataUrl + url, options)
        .then(response => response.json());
    // .then(result => console.log(result))
    // .catch(error => console.log("error", error));
}


/*function roundDot2(number) {
    return Math.round(number * 100) / 100;
}*/

export default {
    data() {
        return {
            connector: {
                isMainInformationCalc: (get_param) => {
                    const token = '';
                    const data = {
                        s: get_param
                    };
                    return this.proxyConnector('cash/get_params', data, token);
                },
                getTokenAccessAndRefresh: (phone, password) => {
                    let data = new FormData();
                    data.append('phone', phone);
                    data.append('password', password);
                    return proxyConnectorToken('api/v1/get_token/', data);
                },
                // getTokenAccessAndRefreshWithBirthday:(phone, birthday) => {
                //     let data = new FormData();
                //     data.append('phone', phone);
                //     data.append('birthday', birthday);
                //     return proxyConnectorToken('api/v1/get_token/', data);
                // },
                getTokenAccess: () => {
					if (this.anketa.new_client_token) {
						return new Promise((resolve) => {
							resolve({success: true})
						});
					}else {
						let data = new FormData();
						data.append("refresh", localStorage.getItem('tokenRefresh'));
						return proxyConnectorToken('api/v1/token/refresh/', data);
					}
                },
                clientExists: (phone) => {
                    return this.proxyConnector('cash/exists/', {phone});
                },
                sopdSend: (phone) => {
                    return this.proxyConnector('cash/sopd_send/', {phone});
                },
                sopdSendWithBirthday: (phone, birthday) => {
                    return this.proxyConnector('cash/sopd_send/', {phone, birthday});
                },
                sopdVerify: (code, phone) => {
                    return this.proxyConnector('cash/sopd_verify/', {code, phone});
                },
                isRegistrationAndCreateApplication: (data) => {
                    console.log('getAuthAndCreateApplication');
                    data.shop_id = this.anketa?.conditions?.shopId;
                    const juicyData = {
                        TimeZone: (0 - new Date().getTimezoneOffset()) / 60,
                        UserAgent: navigator.userAgent,
                        Cookie: this.juicySession,
                        TimeLocal: getDate(),
                        TimeMsk: getDate('Europe/Moscow'),
                        IsJavascriptEnabled: 1,
                        Channel: "SITE",
                    };
                    data.juicyData = juicyData
                    return this.proxyConnector('cash/create/', data);
                },
                saveDocuments: (data) => {
                    const token = localStorage.getItem('token');
                    const token_dt = localStorage.getItem('token_dt')
                    const phone = this.anketa.phone.valueFirstDigitIs9()
                    data.token = token
                    data.token_dt = token_dt
                    data.phone = phone
                    return this.proxyConnector('cash/enrichment/', data, token, token_dt, phone)
                },
                checkPassportIssuer: () => {
                    const data = {
                        divisionCode: this.anketa.divisionCode.cleanValue(),
                        issueDate: this.anketa.issueDate.value,
                    };

                    return new Promise( (resolve,reject) => {
                        axios.post('https://cpoint.ru/Services/AutoComplete/api/Main/PassportIssuer', data)
                            .then( response => resolve(response.data) )
                            .catch( err => reject(err) );
                    });
                },
                isAuthAndCreateApplication: (data) => {
                    data.shop_id = this.anketa?.conditions?.shopId;
                    console.log(data)
                    const juicyData = {
                        TimeZone: (0 - new Date().getTimezoneOffset()) / 60,
                        UserAgent: navigator.userAgent,
                        Cookie: this.juicySession,//
                        TimeLocal: getDate(),
                        TimeMsk: getDate('Europe/Moscow'),
                        IsJavascriptEnabled: 1,
                        Channel: "SITE",
                    };
                    data.juicyData = juicyData
                    return this.proxyConnector('cash/create/', data);
                },
                isRecoveryPassword: (phone, date) => {
                    console.log(phone)
                    const data = {
                        phone : phone,
                        birth_date: date
                    };

                    return this.proxyConnector('cash/drop_pass/', data);
                },
                isStatusApplication: () => {
                    const token = (localStorage.getItem('token'))? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt'))? localStorage.getItem('token_dt'):'';
                    const phone = this.anketa.phone.valueFirstDigitIs9()
                    const dataBody = {
                        token,
                        token_dt,
                        phone
                    }

                    console.log('getAuthAndCreateApplication');
                    return this.proxyConnector('cash/status/', dataBody);
                },
                isInsurancePackage: (id) => {
                    const token = (localStorage.getItem('token')) ? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt')) ? localStorage.getItem('token_dt'):'';
                    const data = {
                        token,
                        token_dt,
                        phone: this.anketa.phone.valueFirstDigitIs9(),
                        set: id
                    };
                    console.log('isInsurancePackage');
                    return this.proxyConnector('cash/pick_options/', data);

                },
                isRequestForSMSCode: () => {
                    const token = (localStorage.getItem('token')) ? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt')) ? localStorage.getItem('token_dt'):'';
                    const phone = this.anketa.phone.valueFirstDigitIs9()
                    const data = {
                        token,
                        token_dt,
                        phone
                    };
                    console.log('isRequestForSMSCode');

                    return this.proxyConnector('cash/send_sign_sms/', data);

                },
                isSingApplication: (code) => {
                    const token = (localStorage.getItem('token')) ? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt')) ? localStorage.getItem('token_dt'):'';
                    const phone = this.anketa.phone.valueFirstDigitIs9()

                    //temp
                    const data = {
                        token,
                        token_dt,
                        phone,
                        signCode: code
                    };

                    // const applicationId = this.anketa.applicationId.value
                    // const data = {
                    //     "Url": "http://192.168.20.74/paylateservice/api/posCredit/v3/sign",
                    //     "Data": {
                    //       "applicationId": '1167309',
                    //       "signCode": code
                    //     }
                    // };
                    console.log('isSingApplication');

                    this.$root.mindbox("подписание заявки");
                    return this.proxyConnector('cash/sign/', data);
                    //temp
                    // return this.proxyConnectorWidget('cash/sign/', data);

                },
                getDadataAddress(query) {
                    return dadata('suggest/address', query);
                },
                getDadataParty(query) {
                    return dadata('suggest/party', query);
                },
                getDadataFio(query, parts = []) {
                    return dadata('suggest/fio', query, parts);
                },
                getDadataBankId(query) {
                    return dadata('suggest/bank', query);
                },

                loanUpdate: (order = {}) => {
                    const token = (localStorage.getItem('token'))? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt'))? localStorage.getItem('token_dt'):'';
                    const phone = this.anketa.phone.valueFirstDigitIs9()
                    const dataBody = {
                        token,
                        token_dt,
                        phone,
                        order
                    }
                    console.log('loan_update');
                    console.log(order)
                    return this.proxyConnector('cash/loan_update/', dataBody);
                },

                getBanks:(date) =>{
                    const data = {
                        phone: this.anketa.phone.valueFirstDigitIs9(),
                        DateTime: date
                    }
                    let url = 'cash/get_banks/'
                    let config = {
                        method: 'GET',
                        url: appTypeUrl + url,
                        headers: {
                            'Content-Type':'application/json',
                            // 'Access-Control-Allow-Origin': '*'
                        },
                        data: data,
                        date: date
                    };

                    return new Promise((resolve, reject) => {
                        axios(config)
                            .then(response => resolve(response.data))
                            .catch(err => reject(err));
                    });
                },

                getBanksFromSbpApi:() => {
                    let config = {
                        method: 'GET',
                        url: 'https://qr.nspk.ru/proxyapp/c2bmembers.json',
                        headers: {
                            'Content-Type':'application/json',
                        },
                    };

                    return new Promise((resolve, reject) => {
                        axios(config)
                            .then(response => resolve(response.data))
                            .catch(err => reject(err));
                    });
                },

                promoVerify:(promocode, phone) => {
                    const token = (localStorage.getItem('token'))? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt'))? localStorage.getItem('token_dt'):'';
                    // const phone = this.anketa.phone.valueFirstDigitIs9()
                    const data = {
                        token,
                        token_dt,
                        phone,
                        promo_code: promocode
                    }
                    return this.proxyConnector('cash/promo_verify/', data);
                },

                promoSave:(promocode, phone) => {
                    const token = (localStorage.getItem('token'))? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt'))? localStorage.getItem('token_dt'):'';
                    // const phone = this.anketa.phone.valueFirstDigitIs9()
                    const data = {
                        token,
                        token_dt,
                        phone,
                        promo_code: promocode
                    }
                    return this.proxyConnector('cash/promo_save/', data);
                }
            }
        }
    },

    methods: {
        proxyConnector(url, data, token, client = {}) {
            let config = {
                method: 'POST',
                url: appTypeUrl + url,
                headers: {
                    'Content-Type':'application/json'
                },
            };

            console.log(this.anketa.new_client_token);

            if (this.anketa.new_client_token || Object.keys(client).length) {
                data.client_id = this.anketa.client_id || client.client_id;
                data.new_client_token = this.anketa.new_client_token || client.new_client_token;
                data.token_timestamp = this.anketa.token_timestamp || client.token_timestamp;
            } else if(!this.anketa.new_client_token || !client) {
                config.headers.Authorization = token;
            }

            config.data = data;

            return new Promise((resolve, reject) => {
                axios(config)
                    .then(response => resolve(response.data))
                    .catch(err => reject(err));
            });
        },

        // proxyConnectorWidget(url, data, token, client = {}) {
        //     let config = {
        //         method: 'POST',
        //         // url: 'https://card.paylate.ru/' + url, // prod
        //         // url: 'https://test-card.paylate.ru/' + url, // test
        //         url: 'https://test-widget.paylate.ru/api/paylate/push',
        //         headers: {
        //             'Content-Type':'application/json'
        //         },
        //     };

        //     console.log(this.anketa.new_client_token);

        //     if (this.anketa.new_client_token || Object.keys(client).length) {
        //         data.client_id = this.anketa.client_id || client.client_id;
        //         data.new_client_token = this.anketa.new_client_token || client.new_client_token;
        //         data.token_timestamp = this.anketa.token_timestamp || client.token_timestamp;
        //     } else if(!this.anketa.new_client_token || !client) {
        //         config.headers.Authorization = token;
        //     }

        //     config.data = data;

        //     return new Promise((resolve, reject) => {
        //         axios(config)
        //             .then(response => resolve(response.data))
        //             .catch(err => reject(err));
        //     });
        // },
    }
}
